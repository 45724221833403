<template>
  <b-modal
    :id="id"
    size="md"
    title="Đề nghị gia hạn giấy phép"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleRequireExtend"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group
        class="mt-1"
        label-for="classfication"
      >
        <label for="user">Ngày bắt đầu giấy phép <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customDateStart"
        >
          <date-time-picker
            v-model="dataRequireExtend.dateStart"
            :disabledInput="true"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group
        class="mt-1"
        label-for="classfication"
      >
        <label for="user">Ngày kết thúc giấy phép <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customDateEnd"
        >
          <date-time-picker
            v-model="dataRequireExtend.dateEnd"
            :disabledInput="true"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BModal,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataRequireExtend: {
        dateStart: '',
        dateEnd: '',
      },
      customDateStart: {
        required: 'Ngày bắt đầu là bắt buộc',
      },
      customDateEnd: {
        required: 'Ngày kết thúc là bắt buộc',
      },
    }
  },
  methods: {
    handleRequireExtend(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleRequireExtend', this.dataRequireExtend)
        }
      })
    },
    resetState() {
      this.dataRequireExtend = {
        numberLicense: '',
      }
    },
  },
}
</script>
