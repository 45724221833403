<template>
  <b-modal
    :id="id"
    size="md"
    title="Đề nghị cấp lại"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleRequireRenew"
    @hidden="resetState"
  >
    <label for="InputHelp">Lý do cấp lại</label>
    <b-form-textarea
      id="InputHelp"
      v-model="dataRequireRenew.reasonRequire"
      rows="3"
      no-resize
    />
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BModal,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    BFormTextarea,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataRequireRenew: {
        reasonRequire: '',
      },
      customCode: {
        required: 'Số giấy cấp phép cấp lại là bắt buộc',
      },
    }
  },
  methods: {
    handleRequireRenew() {
      this.$emit('handleRequireRenew', this.dataRequireRenew)
    },
    resetState() {
      this.dataRequireRenew = {
        reasonRequire: '',
      }
    },
  },
}
</script>
