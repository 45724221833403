<template>
  <div id="filter-custom">
    <div class="page-container pb-0 mb-2">
      <div id="filter-custom">
        <h3
          class="header"
        >
          Bộ lọc
        </h3>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Loại giấy phép <span class="required"></span></label>
              <v-select
                :reduce="label => label.workerLicenseType"
                label="label"
                :options="dataLicense || []"
                :placeholder="'Loại giấy phép'"
                @input="licenseFilter"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ $t(option.workerLicenseTypeString) }}
                </template>
                <template #selected-option-container="{ option }">
                  <div class="vs__selected">
                    {{ $t(option.workerLicenseTypeString) }}
                  </div>
                </template>
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>\
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Trạng thái<span class="required"></span></label>
              <v-select
                :reduce="label => label.status"
                label="label"
                :options="dataStatusFilter || []"
                :placeholder="'Trạng thái'"
                @input="filterStatus"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ $t(option.statusString) }}
                </template>
                <template #selected-option-container="{ option }">
                  <div class="vs__selected">
                    {{ $t(option.statusString) }}
                  </div>
                </template>
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

      </div>
    </div>
    <div class="page-container-table">
      <!--Phần header của bảng -->
      <div class="d-flex justify-content-between">
        <div
          id="btn-all-header"
          class="d-flex"
        >
          <!-- -------------Delete------------- -->
          <button-component
            v-b-tooltip.hover.top="'Xóa nhiều'"
            class="ml-2 button-icon"
            content-btn=""
            variant="danger"
            icon-btn="Trash2Icon"
            :disabled="!showBtnMultiDelete"
            @click="deleteItems"
          />
        </div>
        <div class="header-action">
          <search
            class="search-input mr-1"
            @change="search"
          />
          <b-dropdown
            id="dropdown-right"
            right
            text="Thêm mới"
            variant="primary"
            class="buttom-add mr-2"
            size="lg"
          >
            <b-dropdown-item @click="$router.push({name: 'work-permit-add-license'})">
              Thêm giấy cấp phép
            </b-dropdown-item>
            <b-dropdown-item @click="$router.push({name: 'work-permit-add-certificate'})">
              Thêm giấy xác nhận
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <!--Phần Bảng -->
      <template>
        <vue-good-table
          ref="user-table"
          :columns="columns"
          :rows="dataTable || []"
          style-class="vgt-table"
          :select-options="{
            enabled: true,
            vertialAlignTop: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          @on-selected-rows-change="selectRowTable"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- số giáy phếp -->
            <span v-if="props.column.field == 'numberLicense'">
              {{ props.row.numberLicense }}
            </span>
            <!-- tên -->
            <span v-else-if="props.column.field == 'name'">
              {{ props.row.name }}
            </span>
            <!-- loại -->
            <span v-else-if="props.column.field === 'workerLicenseTypeString'">
              <b-badge
                pill
                :variant="statusVariant(props.row.workerLicenseType)"
                class="border-status"
              >
                <span>{{ props.row.workerLicenseType === 'License' ? 'Giấy cấp phép' : 'Giấy xác nhận' }}</span>

              </b-badge>
            </span>
            <!-- ngày bắt đầu -->
            <span v-else-if="props.column.field == 'dateStart'">
              {{ props.row.dateStart | formatDateToDDMM }}
            </span>
            <!-- ngày kết thúc -->
            <span v-else-if="props.column.field == 'dateEnd'">
              {{ props.row.dateEnd | formatDateToDDMM }}
            </span>
            <!-- ngày hẹn trả -->
            <span v-else-if="props.column.field == 'dateProcess'">
              <span
                v-if="props.row.status === 'PendingRenew'"
              >
                {{ props.row.dateReceiveRenew | formatDateToDDMM }}
              </span>
              <span
                v-if="props.row.status === 'PendingConfirm'"
              >
                {{ props.row.dateReceiveConfirm | formatDateToDDMM }}
              </span>
              <span
                v-if="props.row.status === 'PendingLicensing'"
              >
                {{ props.row.dateReceiveLicense | formatDateToDDMM }}
              </span>
            </span>
            <!-- statusString : Trạng thái  -->
            <span
              v-else-if="props.column.field == 'statusString' "
            >
              <b-badge
                pill
                :variant="colorStatus(props.row.status)"
                class="border-status"
              >
                <span> {{ props.row.statusString }}</span>
              </b-badge>
            </span>
            <!-- Chức năng -->
            <span v-else-if="props.column.field == 'cn'">
              <span>
                <feather-icon
                  v-b-tooltip.hover.top="'Chỉnh sửa'"
                  icon="EditIcon"
                  size="18"
                  @click="editWorkerLicense(props.row.workerLicenseType, props.row.id)"
                />
                <feather-icon
                  v-b-tooltip.hover.top="'Xóa'"
                  icon="Trash2Icon"
                  size="18"
                  class="ml-2"
                  @click="deleteItem(props.row.id)"
                />
                <span>
                  <b-dropdown
                    variant="link"
                    boundary="viewport"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="18"
                        class="text-body"
                      />
                    </template>
                    <!-- xem chi tiet  -->
                    <span v-if="props.row.workerLicenseType === 'License' && ['Revoke', 'Licensing', 'Renew', 'Extend'].includes(props.row.status)">
                      <b-dropdown-item
                        v-if="props.row.status !== 'Revoke'"
                        @click="openRequireExtend(props.row.id)"
                      >
                        <feather-icon
                          icon="ClockIcon"
                          class="mr-50"
                        />
                        <span>{{ 'Đề nghị gia hạn' }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="openRequireRenew(props.row.id)">
                        <feather-icon
                          icon="RotateCwIcon"
                          class="mr-50"
                        />
                        <span>{{ 'Đề nghị cấp lại' }}</span>
                      </b-dropdown-item>
                    </span>

                    <b-dropdown-item
                      v-if="props.row.workerLicenseType === 'Confirm' && ['Confirm'].includes(props.row.status)"
                      @click="openRequireRenew(props.row.id)"
                    >
                      <feather-icon
                        icon="RotateCwIcon"
                        class="mr-50"
                      />
                      <span>{{ 'Đề nghị cấp lại' }}</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="props.row.workerLicenseType === 'Confirm' && ['Revoke', 'Licensing', 'Renew', 'Extend'].includes(props.row.status)"
                      @click="openModalAccept(props.row.id)"
                    >
                      <feather-icon
                        icon="RotateCwIcon"
                        class="mr-50"
                      />
                      <span>{{ 'Đề nghị cấp lại' }}</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="openModalAddFile(props.row.id)">
                      <feather-icon
                        icon="FilePlusIcon"
                        class="mr-50"
                      />
                      <span>{{ 'Thêm file đính kèm' }}</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="['PendingLicensing', 'PendingConfirm', 'PendingRenew'].includes(props.row.status)"
                      @click="openModalFileSend(props.row.id, props.row.status)"
                    >
                      <feather-icon
                        icon="FilePlusIcon"
                        class="mr-50"
                      />
                      <span>{{ 'Thêm hồ sơ cần nộp' }}</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="props.row.workerLicenseType === 'License' && ['Revoke'].includes(props.row.status)"
                      @click="openModalSeePdf(props.row.id)"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        class="mr-50"
                      />
                      <span>{{ 'Tải xuống giấy phép' }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>

            </span>
          </template>
        </vue-good-table>
        <my-pagination
          :totalItems="totalRecord"
          :currentPage="urlQuery.pageNumber"
          @pageClick="handlePageClick"
        />
      </template>

      <!-- modal xóa giấy phép-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />

      <!-- Đề nghị cấp lại giấy xác nhận-->
      <confirm-modal
        :id="confirmModalConfirmId"
        :content="'Bạn có muốn cấp lại không?'"
        :title="'Bạn có chắc chắn muốn cấp lại'"
        @accept="actionAccept"
      />

      <!--Tạo file đính kèm-->
      <modal-create-file
        :id="modalCreateFile"
        :dataAddFile="dataAddFile"
        :totalRecordAddFile="totalRecordAddFile"
        :queryParams="queryParams"
        @handlePageClickPage="handlePageClickPage"
        @handleAddFile="handleAddFile"
        @deleteFile="deleteFile"
        @searchFile="searchFile"
      />

      <!--Đề nghị gia hạn giấy phép-->
      <modal-require-extend
        :id="modalRequireExtend"
        @handleRequireExtend="handleRequireExtend"
      />

      <!--Đề nghị cấp lại giấy phép-->
      <modal-require-renew
        :id="modalRequireRenew"
        @handleRequireRenew="handleRequireRenew"
      />

      <!-- modal xem file pdf -->
      <modal-see-pdf
        :id="modalSeePdf"
        :pdfsrc="pdfsrc"
        @downloadFile="downloadFilePdf"
      />

      <!-- modal thêm hồ sơ cần nộp -->
      <modal-file-send
        :id="modalFileSend"
        :dataTableModal="dataTableModal"
        :fetchListFileSend="fetchListFileSend"
        :urlQueryModal="urlQueryModal"
      />
    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'

import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem, BButton,
  VBTooltip,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import Search from '@/components/search/Search.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import DataFilter from '../constants/DataFilter'
import ModalCreateFile from './components/ModalCreateFile.vue'
import ModalRequireExtend from './components/ModalRequireExtend.vue'
import ModalRequireRenew from './components/ModalRequireRenew.vue'
import ModalSeePdf from './components/ModalSeePdf.vue'
import ModalFileSend from './components/ModalFileSend.vue'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    VSelect,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    ButtonComponent,
    Search,
    ShowFile,
    ModalCreateFile,
    ModalRequireExtend,
    ModalRequireRenew,
    ModalSeePdf,
    ModalFileSend,
  },
  filters: {
    formatDateToDDMM,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      modalRequireRenew: 'confirm-require-renew',
      modalRequireExtend: 'confirm-require-extend',
      modalDetailUser: 'showFile',
      modalCreateFile: 'comfirm-create-files',
      confirmModalConfirmId: 'comfirm-modal-confim',
      modalSeePdf: 'comfirms-pdfs',
      modalFileSend: 'conform-modal-send',
      // ==dữ liệu bảng
      rows: [],
      columns: [
        {
          label: 'SỐ GIẤY PHÉP',
          field: 'numberLicense',
          width: '200px',
          tdClass: 'actiontd',
          thClass: 'actionth',
        },
        {
          label: 'HỌ TÊN',
          field: 'name',

        },
        {
          label: 'LOẠI GIẤY',
          field: 'workerLicenseTypeString',
        },
        {
          label: 'NGÀY BẮT ĐẦU',
          field: 'dateStart',
        },
        {
          label: 'NGÀY KẾT THÚC',
          field: 'dateEnd',
        },
        {
          label: 'NGÀY HẸN TRẢ',
          field: 'dateProcess',
        },
        {
          label: 'TRẠNG THÁI',
          field: 'statusString',
          width: '300px',
        },
        {
          label: 'CHỨC NĂNG',
          width: '200px',
          tdClass: 'actiontd',
          thClass: 'actionth',

          field: 'cn',
        },
      ],
      // ==phân trang
      totalPages: 0,
      currentPage: 10,
      // ==Dữ liệu fetch list
      urlQuery: {
        bussinessId: null,
        workerLicenseType: null,
        status: null,
        key: null,
        pageSize: 10,
        pageNumber: 1,
      },
      dataTable: [],
      totalRecord: 0,
      dataStatusFilter: DataFilter.dataStatus,
      dataLicense: DataFilter.dataWorkerLicenseType,
      confirmModalId: 'confirm-modal',
      modalContent: '',
      deleteIds: [],
      showBtnMultiDelete: false,
      dataAddFile: [],
      totalRecordAddFile: 0,
      queryParams: {
        workerLicenseId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      urlQueryModal: {
        workerLicenseId: '',
        type: '',
      },
      workerLicenseRequireId: null,
      pdfsrc: null,
      typeStatus: null,
      dataTableModal: [],
    }
  },
  computed: {
    // màu status
    // eslint-disable-next-line consistent-return
    colorStatus() {
      const statusColor = {
        None: 'info',
        NonLiscening: 'secondary',
        PendingConfirm: 'warning',
        Confirm: 'success',
        RejectConfirm: 'danger',
        Licensing: 'primary',
        RejectLicense: 'danger',
        PendingRenew: 'warning',
        PendingExtend: 'warning',
        Revoke: 'danger',
        PendingLicensing: 'warning',
        Extend: 'success',
        Renew: 'primary',
      }
      return status => statusColor[status]
    },
    // màu kiểu
    // eslint-disable-next-line consistent-return
    statusVariant() {
      // eslint-disable-next-line default-case
      const typeColor = {
        License: 'light-primary',
        Confirm: 'light-success',
      }
      return color => typeColor[color]
    },
  },
  created() {
    this.fetchDataWorkerPermit(this.urlQuery)
  },
  // =============================METHOD======================
  methods: {

    // Mở modal xem file pdf
    openModalSeePdf(id) {
      this.workerLicenseRequireId = id
      axiosApiInstance({
        url: '/WorkerLicense/file-revoke-license', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { workerLicenseId: this.workerLicenseRequireId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Tải file pdf
    downloadFilePdf() {
      axiosApiInstance({
        url: '/WorkerLicense/file-revoke-license', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { workerLicenseId: this.workerLicenseRequireId },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'GiayThuHoiGiayPhep.pdf') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    // Mở modal Thêm hồ sơ cần nộp
    async openModalFileSend(id, status) {
      this.urlQueryModal.workerLicenseId = id
      this.typeStatus = status
      await this.fetchListFileSend(this.urlQueryModal)
      this.$bvModal.show(this.modalFileSend)
    },

    // Lấy danh sách hồ sơ cần nộp
    async fetchListFileSend(urlQuery) {
      switch (this.typeStatus) {
        case 'PendingLicensing':
          urlQuery.type = 'CM'
          break
        case 'PendingConfirm':
          urlQuery.type = 'XN'
          break
        case 'PendingRenew':
          urlQuery.type = 'CL'
          break
        default:
          break
      }
      const data = await axiosApiInstance.get(ConstantsApi.LIST_FILE_REQUIRES, {
        params: urlQuery,
      })
      this.dataTableModal = data?.data
    },

    // Mở modal gia hạn cấp phép
    openRequireExtend(id) {
      this.workerLicenseRequireId = id
      this.$bvModal.show(this.modalRequireExtend)
    },
    // Mở modal cấp lại giấy phép
    openRequireRenew(id) {
      this.workerLicenseRequireId = id
      this.$bvModal.show(this.modalRequireRenew)
    },

    openModalAccept(id) {
      this.workerLicenseRequireId = id
      this.$bvModal.show(this.confirmModalConfirmId)
    },

    async actionAccept() {
      const model = {
        id: this.workerLicenseRequireId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.REQUIRE_RENEW, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gửi cấp lại thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.confirmModalConfirmId)
        this.fetchDataWorkerPermit(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gửi cấp lại không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Đề nghị gia hạn giấy phép
    async handleRequireExtend(val) {
      const model = {
        ...val,
        id: this.workerLicenseRequireId,
      }

      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.REQUIRE_EXTEND, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gửi gia hạn thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalRequireExtend)
        this.fetchDataWorkerPermit(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gửi gia hạn không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Đề nghị cấp lại giấy phép
    async handleRequireRenew(val) {
      const model = {
        ...val,
        id: this.workerLicenseRequireId,
      }

      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.REQUIRE_RENEW, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gửi cấp lại thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalRequireRenew)
        this.fetchDataWorkerPermit(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gửi cấp lại không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Thêm file vào giấy phép lao động
    async handleAddFile(val) {
      val.append('WorkerLicenseId', this.queryParams.workerLicenseId)
      await axiosApiInstance.post(ConstantsApi.ADD_FILE_TO_LICENSE, val).then(res => {
        if (res.status === 200) {
          this.$root.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.fetchFileAddWorkerLicense(this.queryParams)
        } else {
          this.$root.$bvToast.toast('Thêm không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },

    // Mở modal thêm file
    async openModalAddFile(id) {
      this.queryParams.workerLicenseId = id
      await this.fetchFileAddWorkerLicense(this.queryParams)
      this.$bvModal.show(this.modalCreateFile)
    },

    // Danh sách file để thêm
    async fetchFileAddWorkerLicense(queryParams) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_WORKER_ADD_FILE, {
        params: queryParams,
      })
      this.dataAddFile = data.data.pageLists
      this.totalRecordAddFile = data.data.totalRecord
      this.$hideLoading()
    },

    // Lấy danh sách đề nghị cấp phép người lao động
    async fetchDataWorkerPermit(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_WORKERS_PERMIT, {
        params: urlQuery,
      })
      this.dataTable = data.data.pageLists
      this.totalRecord = data.data.totalRecord
      this.$hideLoading()
    },

    // Chuyển hướng tới chỉnh sửa cấp phép hoặc xác nhận
    editWorkerLicense(val, id) {
      if (val === 'License') {
        this.$router.push({ name: 'work-permit-edit-license', params: { id } })
      }
      if (val === 'Confirm') {
        this.$router.push({ name: 'work-permit-edit-certificate', params: { id } })
      }
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchDataWorkerPermit(this.urlQuery)
    },

    // Xử lý khi ấn nút phân trang thêm file
    handlePageClickPage(pageNumber, pageSize) {
      this.queryParams.pageNumber = pageNumber
      this.queryParams.pageSize = pageSize
      this.fetchFileAddWorkerLicense(this.queryParams)
    },

    // lấy danh sách khi lọc theo trạng thái
    filterStatus(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.status = val
        this.fetchDataWorkerPermit(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.status = ''
        this.fetchDataWorkerPermit(this.urlQuery)
      }
    },

    // Lấy dan hsachs lọc theo giấy phép
    licenseFilter(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.workerLicenseType = val
        this.fetchDataWorkerPermit(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.workerLicenseType = ''
        this.fetchDataWorkerPermit(this.urlQuery)
      }
    },

    // lấy danh sách khi tìm kiếm file
    searchFile(val) {
      if (val != null) {
        this.queryParams.pageNumber = 1
        this.queryParams.key = val
        this.fetchFileAddWorkerLicense(this.queryParams)
      } else {
        this.queryParams.pageNumber = 1
        this.queryParams.key = ''
        this.fetchFileAddWorkerLicense(this.queryParams)
      }
    },

    // lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchDataWorkerPermit(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchDataWorkerPermit(this.urlQuery)
      }
    },

    // Xóa giấp phép lao động
    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa file trong giấy phep lao dộng
    async deleteFile(val) {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_FILE_TO_LICENSE, {
        ids: val,
        workerLicenseId: this.queryParams.workerLicenseId,
      }).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchFileAddWorkerLicense(this.queryParams)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_WORKER_LICENSE, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataWorkerPermit(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

  },
}
</script>

<style lang="scss">
#filter-custom {
  .buttom-add {
    button {
      font-size: 14px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
    }
  }
  .border-status {
    border-radius: 4px;
  }
}
</style>
