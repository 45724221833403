var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":_vm.id,"size":"lg","title":"Thêm file đính kèm","centered":"","hide-footer":""}},[_c('button-all-header',{staticClass:"custtom-header",attrs:{"contentBtnAdd":'Thêm',"arrayExcel":_vm.arrayExcel,"showBtnMultiDelete":_vm.showBtnMultiDelete,"hideImportFile":false,"hideExportFile":false,"hideDowload":false},on:{"clickDelete":_vm.deleteItems,"clickAdd":_vm.showModalAddFile,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataAddFile || [],"sort-options":{
        enabled: false,
      },"select-options":{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_vm._v(" "+_vm._s(props.row.name)+" ")]):_vm._e(),(props.column.field == 'action')?_c('span',[_c('span',[_c('feather-icon',{attrs:{"icon":"DownloadIcon","size":"18"},on:{"click":function($event){return _vm.downloadFile(props.row.id, props.row.name)}}}),_c('feather-icon',{staticClass:"ml-2",attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.deleteItem(props.row.id)}}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecordAddFile,"currentPage":_vm.queryParams.pageNumber},on:{"pageClick":_vm.handlePageClickPage}})],1),_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"content":_vm.modalContent},on:{"accept":_vm.deleteFile}}),_c('modal-add-file',{attrs:{"id":_vm.modalAddFile},on:{"handleAddFile":_vm.handleAddFile}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }